<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :pagination-page-size="20"
      url="data-statistics/fault-alarm-statistics/page">
      <el-button
        v-if="$auth('故障报警统计导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="省市地区">
            <vm-district-select
              :province-code.sync="filter.provinceCode"
              :city-code.sync="filter.cityCode"
              :area-code.sync="filter.districtCode">
            </vm-district-select>
          </vm-search>
          <vm-search :label="$l('project.name','维保班组')" prop="teamName">
            <vm-autocomplete
              :filter.sync="filter.teamName"

              url="organization/team/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼盘">
            <vm-autocomplete
              :filter.sync="filter.realEstateName"
              url="real-estate/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼宇">
            <vm-autocomplete
              :filter.sync="filter.buildingName"
              url="real-estate/building/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="时间范围">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="province" label="省" align="center" width="120"></el-table-column>
      <el-table-column prop="city" label="市" align="center" width="120"></el-table-column>
      <el-table-column prop="district" label="区" align="center" width="120"></el-table-column>
      <el-table-column prop="teamName" label="班组名称" align="center" width="120">
        <template slot="header" slot-scope="">
          班组名称 <el-button type="text" icon="el-icon-sort" @click="sort('teamName')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="leaderName" label="维保队长" align="center" width="120">
        <template slot="header" slot-scope="">
          维保队长 <el-button type="text" icon="el-icon-sort" @click="sort('leaderName')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" align="center" width="120">
        <template slot="header" slot-scope="">
          联系电话 <el-button type="text" icon="el-icon-sort" @click="sort('phone')"></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘" align="center" width="170"></el-table-column>
      <el-table-column prop="buildingName" label="楼宇" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="faultCode" label="故障代码" align="center" width="170"></el-table-column>
      <el-table-column prop="faultName" label="故障名称" align="center" width="170"></el-table-column>
      <el-table-column prop="faultAlarmCount" label="故障报警次数" align="center" width="170">
        <template slot="header" slot-scope="">
          故障报警次数 <el-button type="text" icon="el-icon-sort" @click="sort('faultAlarmCount')"></el-button>
        </template>
      </el-table-column>
    </vm-table>
  </div>
</template>
<script>

  import {export2Excel, getWidth} from "@/util";
  import print from  "print-js";
  import moment from "moment";
  import VmAutocomplete from "@/components/VmAutocomplete";
  export default {
    components: {VmAutocomplete},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          realEstateName:"",
          maintenanceType:"as_need",
          arr:[{
            orderColumnName:"faultAlarmCount",
            asc:0,
          }],
          orderColumnNames:"faultAlarmCount|0",
        },
        senior: false,
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.createdTimeFrom = this.filter.seTime[0];
          this.filter.createdTimeTo = this.filter.seTime[1];
        }
      },
      exportExcelData() {
        const title={
          province:"省",
          city:"市",
          district:"区",
          teamName:"班组名称",
          leaderName:"队长名称",
          phone:"联系电话",
          realEstateName:"楼盘名称",
          buildingName:"楼宇名称",
          regCode:"注册代码",
          faultCode:"故障代码",
          faultName:"故障名称",
          faultAlarmCount:"故障报警次数",
        };
        this.$http.get("data-statistics/fault-alarm-statistics/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`故障报警数据统计-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.name}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        let routeUrl = this.$router.resolve({
          path: "/monitor/LiftMonitor",
          query: {elevatorId},
        });
        window.open(routeUrl.href, "_blank");
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
    .treeBox{
        border: 1px solid #E6E6E6;
        padding: 10px;
        margin-right: 10px;
        width: calc(100% - 1300px);
        max-height: 1080px;
        overflow-y: auto;
    }
</style>
